<template>
	<div class="contacts">
		<div class="contact--wrapper">
			<div class="title">
				<h2 v-for="(tit, index) in title.split(' ')" :key="index">{{tit}}</h2>
			</div>
			<div class="contact--logo"></div>
			<div class="contact--success">
				<div class="success--wrapper">
					<h2>
						<span v-for="(text, index) in $t('contatti.titolo_risultato').split('#')" :key="index">{{text}}</span>
					</h2>
				</div>
			</div>
			<div class="form">
				<form @submit.prevent="sendContactForm">
					<div class="form--item">
						<label for="product-info-name">{{$t("generale.form.nome")}}*</label>
						<input type="text" id="product-info-name" v-model="contactName">
						<span class="error" v-if="fieldErrors.name">{{fieldErrors.name}}</span>
					</div>
					<div class="form--item">
						<label for="product-info-mail">{{$t("generale.form.mail")}}*</label>
						<input type="email" id="product-info-mail" v-model="contactMail">
						<span class="error" v-if="fieldErrors.mail">{{fieldErrors.mail}}</span>
					</div>
					<div class="form--item">
						<label for="product-info-tel">{{$t("generale.form.tel")}}</label>
						<input type="text" id="product-info-tel" v-model="contactTel">
					</div>
					<div class="form--item">
						<label for="product-info-subject">{{$t("generale.form.oggetto")}}</label>
						<input type="text" id="product-info-subject" v-model="contactSubject">
					</div>
					<div class="form--item">
						<label for="product-info-messaggio">{{$t("generale.form.messaggio")}}</label>
						<textarea id="product-info-messaggio" v-model="contactMessage"></textarea>
					</div>
					<p class="privacy--margin">{{ $t("generale.form.privacy_contacts_1") }}</p>
					<div class="form--item privacy" :class="{ 'error' : fieldErrors.privacy }">
						<input type="checkbox" v-model="contactPrivacy" id="contact-privacy">
						<div class="check" :class="{ 'selected' : contactPrivacy }"></div>
						<label for="contact-privacy">{{ $t("generale.form.privacy_contacts_2") }}</label>
					</div>
					<button @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')" class="link--mouse-small" type="submit">{{$t("generale.send_message")}}</button>
				</form>
			</div>
			<div class="address" v-if="Object.keys(pageCont).length" v-html="pageCont.content.rendered"></div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import gsap from 'gsap';
export default {
	data() {
		return {
			title: this.$t('contatti.titolo'),
			page: parseInt(this.$t('contatti.page_id')),
			pageCont: {},
			contactName: '',
			contactMail: '',
			contactTel: '',
			contactSubject: '',
			contactMessage: '',
			contactPrivacy: false,
			//eslint-disable-next-line
			reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
			fieldErrors: {
				name: '',
				mail: '',
				privacy: false
			}
		}
	},
	metaInfo() {
		return{
			title: this.$t('head.title.contatti'),
			titleTemplate: '%s | Wearlight',
		}
  },
	methods: {
		getPage() {
			axios
			.get(process.env.VUE_APP_API_URL + '/wp/v2/pages/' + this.page)
			.then(response => {
				this.pageCont = response.data
			})
		},
		sendContactForm() {
			this.fieldErrors.name = ''
			this.fieldErrors.mail = ''
			this.fieldErrors.privacy = false
			if(this.contactName.trim() != '' && this.contactMail.trim() != '' && this.validateEmail(this.contactMail.trim()) && this.contactPrivacy){
				this.$formLoaderVisible = true
				let contacts = {
					name: this.contactName,
					email: this.contactMail,
					tel: this.contactTel,
					subject: this.contactSubject,
					message: this.contactMessage,
					lang: localStorage.getItem('lang')
				};
				axios
					.post(process.env.VUE_APP_API_URL + '/wl/v1/contactMail', contacts)
					.then(res => {
						this.$formLoaderVisible = false
						if(res.data.success){
							gsap.to(document.querySelector('.form'), {duration: 0.5, height: 0, autoAlpha: 0, ease: 'power2.out', onComplete: () => {
								gsap.to(document.querySelector('.form'), {duration: 0, delay: 0.05, display: 'none'})
								window.scrollTo(0,0);
								gsap.to(document.querySelector('.contact--success'), {duration: 0, delay: 0.05, display: 'block'})
								gsap.to(document.querySelector('.contact--success'), {duration: 0.5, delay: 0.5, autoAlpha: 1, ease: 'power2.out'})
							}})
						}
					});
			}else{
				if(this.contactName.trim() == ''){
					this.fieldErrors.name = this.$t('contatti.campo_vuoto')
				}
				if(this.contactMail.trim() == '' || !this.validateEmail(this.contactMail.trim())){
					if(this.contactMail.trim() == ''){
						this.fieldErrors.mail = this.$t('contatti.campo_vuoto')
					}else{
						this.fieldErrors.mail = this.$t('signup.mail_errata')
					}					
				}
				if(!this.contactPrivacy){
					this.fieldErrors.privacy = true
				}
			}
		},
		validateEmail(email){
      return this.reg.test(email);
		}
	},
	watch: {
		'$route.params.locale': function(){
			this.page = parseInt(this.$t('contatti.page_id')),
			this.getPage();
		}
	},
	beforeMount() {
		this.getPage()
	},
  mounted() {
    /* let container = document.querySelector('.contact--logo')
    new LOLLO.Logo3D(container) */
  }
}
</script>

<style lang="scss">
.contacts {
	position: relative;
	width: 100%;
	height: auto;
	padding-top: 80px;
	@include mobile {
		padding-top: 60px;
	}
	.contact--wrapper {
		position: relative;
		width: 100%;
		height: auto;
		padding: 10vh 1vw 0 1vw;
		box-sizing: border-box;
		min-height: calc(100vh - 80px);
		display: flex;
		justify-content: center;
		align-items: flex-start;
		@include mobile {
			min-height: calc(100vh - 60px);
			padding: 0 5vw;
			flex-direction: column;
		}
		.title {
			position: absolute;
			left: 1vw;
			top: 5vh;
			width: auto;
			display: inline-flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;
			z-index: 5;
			@include mobile {
				position: relative;
				left: auto;
				top: auto;
				width: 100%;
				padding: 10vh 0;
				order: 1;
			}
			h2 {
				margin: 0;
				font-family: 'Suisse Int';
				font-weight: 400;
				color: $black;
				font-size: 10vw;
				line-height: 10vw;
				text-transform: uppercase;
				&:last-of-type {
					margin-left: 10vw;
					@include mobile {
						margin-left: 0;
					}
				}
			}
		}
		.contact--logo {
			position: absolute;
			top: 20vh;
			right: 5vw;
			width: 20vw;
			height: auto;
		}
		.address {
			position: absolute;
			top: calc(100vh - 80px - 1vw);
			right: 1vw;
			text-align: right;
			z-index: 5;
			transform: translateY(-100%);
			@include mobile {
				position: relative;
				top: auto;
				right: auto;
				transform: translateY(0);
				order: 2;
				text-align: left;
				margin-bottom: 10vh;
			}
			p {
				color: $black;
				font-size: 14px;
				line-height: 20px;
				font-weight: 400;
				font-family: 'Suisse Int';
				margin: 0 0 5vh 0;
				text-transform: uppercase;
				a {
					color: $black;
				}
				&:last-of-type {
					margin: 0;
				}
			}
		}
		.contact--success {
			position: relative;
			width: 30vw;
			height: auto;
			background-color: $white;
			border: 1px solid $black;
			z-index: 6;
			box-sizing: border-box;
			margin-bottom: 10vh;
			overflow: hidden;
			box-sizing: border-box;
			padding: 5vh 3vw;
			display: none;
			visibility: hidden;
			@include mobile {
				order: 3;
				width: 100%;
				background-color: transparent;
				border: 0;
			}
			.success--wrapper {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				h2 {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 0;
					span {
						font-family: 'Suisse Int';
						font-weight: 500;
						color: $black;
						font-size: 5vw;
						line-height: 5vw;
						text-transform: uppercase;
						&:first-of-type {
							font-family: 'Saol Display';
							font-weight: 500;
							color: $black;
							font-size: 5vw;
							line-height: 4.5vw;
							text-transform: uppercase;
							display: block;
							margin-bottom: 0.5vw;
						}
					}
				}
			}
		}
		.form {
			position: relative;
			width: 30vw;
			height: auto;
			background-color: $white;
			border: 1px solid $black;
			z-index: 6;
			box-sizing: border-box; 
			margin-bottom: 10vh;
			overflow: hidden;
			@include mobile {
				order: 3;
				width: 100%;
				background-color: transparent;
				border: 0;
			}
			form {
				padding: 5vh 3vw;
				@include mobile {
					padding: 0;
				}
				.privacy--margin {
					position: relative;
					width: 100%;
					height: auto;
					margin: 20px 0;
					font-family: 'Suisse Int';
					color: $black;
					font-size: 12px;
					line-height: 14px;
				}
				.form--item {
					position: relative;
					width: 100%;
					display: inline-flex;
					flex-direction: column;
					margin-bottom: 30px;
					box-sizing: border-box;
					label {
						font-family: 'Suisse Int';
						text-transform: uppercase;
						color: $black;
						font-size: 14px;
						display: block;
					}
					input {
						position: relative;
						width: 100%;
						font-family: 'Suisse Int';
						color: $black;
						font-size: 16px;
						line-height: 20px;
						border-radius: 0;
						padding: 10px;
						border: 0;
						border-bottom: 1px solid $black;
						box-sizing: border-box;
						&:focus {
							outline: none;
						}
					}
					textarea {
						position: relative;
						width: 100%;
						height: 7vh;
						font-family: 'Suisse Int';
						color: $black;
						font-size: 16px;
						line-height: 20px;
						border-radius: 0;
						padding: 10px;
						border: 0;
						//border: 1px solid $black;
						box-sizing: border-box;
						resize: none;
						&:focus {
							outline: none;
						}
					}
					&.half {
						width: 50%;
						&.left {
							padding-right: 1vw;
						}
						&.right {
							padding-left: 1vw;
						}
					}
					&.third {
						width: calc(100% / 3);
						&.left {
							padding-right: 1vw;
						}
						&.middle {
							padding: 0 1vw;
						}
						&.right {
							padding-left: 1vw;
						}
					}
					&.twothird {
						width: calc(200% / 3);
						&.left {
							padding-right: 1vw;
						}
					}
					&.privacy {
						position: relative;
						width: 100%;
						display: flex;
						justify-content: flex-start;
						flex-direction: row;
						input {
							display: none;
						}
						.check {
							position: relative;
							width: 20px;
							height: 20px;
							border: 1px solid $black;
							box-sizing: border-box;
							flex-shrink: 0;
							&.selected {
								&:after {
									content: '';
									width: 12px;
									height: 12px;
									position: absolute;
									background-color: $black;
									top: 3px;
									left: 3px;
								}
							}
						}
						label {
							line-height: 20px;
							color: $black;
							font-family: 'Suisse Int';
							text-transform: uppercase;
							margin-left: 10px;
						}
						&.error {
							.check {
								border-color: $red
							}
							label {
								color: $red;
							}
						}
					}
					.error {
						position: absolute;
						bottom: -20px;
						left: 0;
						width: 100%;
						text-align: right;
						font-family: 'Suisse Int';
						color: $red;
						font-size: 12px;
						line-height: 20px;
					}
				}
				button {
					border: 1px solid $black;
					font-family: 'Suisse Int';
					font-weight: 400;
					color: $black;
					font-size: 12px;
					line-height: 12px;
					text-transform: uppercase;
					border-radius: 50px;
					padding: 15px;
					width: auto;
					transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
					background-color: $white;
					&:hover {
						background-color: $black;
						color: $white;
						transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
					}
				}
			}
		}
	}
}
</style>